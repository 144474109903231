import React from 'react';

import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
} from 'react-router-dom';

import AboutCompanyScreen from '../../screens/About';
import BlogScreen from '../../screens/Blog';
import ContactScreen from '../../screens/Contact';
import ChooseCountryScreen from '../../screens/Countries';
import Error404Screen from '../../screens/Errors/404';
import HelpScreen from '../../screens/Help/HelpCenter';
import TipsCustomerScreen from '../../screens/Help/Tips/TipsCustomer';
import TipsPartnerScreen from '../../screens/Help/Tips/TipsPartner';
import BeModeleScreen from '../../screens/Landings/BeModele';

import LandingHomeScreen from '../../screens/Landings/Home';

import PricesScreen from '../../screens/Prices';
import TermCookiesScreen from '../../screens/Terms/TermCookies';
import TermPrivacyScreen from '../../screens/Terms/TermPrivacy';
import TermUseScreen from '../../screens/Terms/TermUse';

import NameRoutes from '../names';



function AppRoutes() {
  return (

    <BrowserRouter>

      <Routes>

        <Route path={NameRoutes.LandingHomeScreen} element={<LandingHomeScreen />} />
        <Route path={NameRoutes.BeModeleScreen} element={<BeModeleScreen />} />

        <Route path={NameRoutes.PricesScreen} element={<PricesScreen />} />
        <Route path={NameRoutes.AboutCompanyScreen} element={<AboutCompanyScreen />} />
        <Route path={NameRoutes.BlogScreen} element={<BlogScreen />} />

        <Route path={NameRoutes.ChooseCountryScreen} element={<ChooseCountryScreen />} />

        <Route path={NameRoutes.ContactScreen} element={<ContactScreen />} />
        <Route path={NameRoutes.HelpScreen} element={<HelpScreen />} />
        <Route path={NameRoutes.TipsCustomerScreen} element={<TipsCustomerScreen />} />
        <Route path={NameRoutes.TipsPartnerScreen} element={<TipsPartnerScreen />} />

        <Route path={NameRoutes.TermUseScreen} element={<TermUseScreen />} />
        <Route path={NameRoutes.TermPrivacyScreen} element={<TermPrivacyScreen />} />
        <Route path={NameRoutes.TermCookies} element={<TermCookiesScreen />} />

        <Route path={NameRoutes.Error404Screen} element={<Error404Screen />} />


        {/* Error 404 - Not found */}
        <Route path="*" element={<Navigate to={NameRoutes.Error404Screen} />} />

      </Routes>

    </BrowserRouter>

  );
}



export default AppRoutes;
